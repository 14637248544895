import React, { memo } from 'react';
import { Wrapper } from './tab.styled';
import { useLocation, useNavigate } from 'react-router';
import Quest from '@/assets/tab/quest.png';
import Referral from '@/assets/tab/referral.png';
import Boost from '@/assets/tab/boost.png';
import Hyperdoge from '@/assets/tab/hyperdog.png';
import Hyperpass from '@/assets/tab/hyperpass.png';
import { toast } from 'react-toastify';

const tabList = [
  {
    navigate: '/',
    icon: Hyperdoge,
    title: 'Rocket',
    active: true
  },
  {
    navigate: '/boost',
    icon: Boost,
    title: 'Boost',
    active: true
  },
  {
    navigate: '/quest',
    icon: Quest,
    title: 'Quest',
    active: true
  },
  {
    navigate: '/referral',
    icon: Referral,
    title: 'Referral',
    active: true
  },
  {
    navigate: '/leaderboard',
    icon: Hyperpass,
    title: 'Leaderboard',
    active: true
  }
];

const TabComponent = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  console.log('pathname', pathname);
  return (
    <Wrapper>
      <div className='w-full app-container p-2 flex'>
        {tabList.map((tab, key) => {
          return (
            <div
              key={key}
              onClick={() => {
                if (!tab.active) {
                  toast.dismiss();
                  toast.info('Coming soon!');
                  return;
                }
                navigate(tab.navigate);
              }}
              className={`flex flex-col items-center gap-1 flex-1 p-2 rounded-2xl cursor-pointer hover:scale-95 transition-all ${
                pathname === tab.navigate && '!bg-[#E1D6EF]'
              }`}
            >
              <img src={tab.icon} className='w-10' alt='' />
              <span className={`font-medium text-xs text-[#948E9C] ${pathname === tab.navigate && '!text-[#10051C]'}`}>
                {tab.title}
              </span>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

export const Tab = memo(TabComponent);
export default Tab;
