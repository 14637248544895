import styled from 'styled-components';
import checkinBg from '@/assets/check-in/bg.png';
export const Wrapper = styled.div`
  width: 100vw !important;
  min-height: 100svh;
  background-size: 100% 100%;
  padding: 24px;
  position: relative;
  z-index: 1000001;
  .step-1 {
    background: url(${checkinBg});
    background-size: cover;
    padding: 16px;
  }

  .step-2 {
    background: url(${checkinBg});
    background-size: cover;
    padding: 24px;
  }
`;
