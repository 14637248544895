import { UserRepository } from '@/repository/user.repository';
import WebApp from '@twa-dev/sdk';
import { setAccessToken } from '@/utilities';
import { useDispatch } from 'react-redux';
import { updateAccount } from '@/redux';
import { toast } from 'react-toastify';
import { useState } from 'react';
export const useLoginWithTelegram = () => {
  const dispatch = useDispatch();
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const handleLogin = async (point?: string, referralCode?: string) => {
    setIsLoadingLogin(true);
    const tonOauthResponse = await UserRepository.loginWithTelegram(
      WebApp.initData ||
        'query_id=AAG07mw4AwAAALTubDgzQP4t&user=%7B%22id%22%3A7389114036%2C%22first_name%22%3A%22K%22%2C%22last_name%22%3A%22%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%2C%22photo_url%22%3A%22https%3A%5C%2F%5C%2Ft.me%5C%2Fi%5C%2Fuserpic%5C%2F320%5C%2FpMO63JCJlQI6ma7w5j1tfeNxgygQL60LD3eWSBVNxawbtrUZ_L7KkwuyzL00GdlN.svg%22%7D&auth_date=1732160143&signature=LH1ShXRP4ojURJj1RTg6hrXC46m2rhQ4JKbH6I2c7CxB7Q_XcUwlQVNSvcnzJvVNlGlqOOILs9gyhanp9r35DA&hash=48dd2d07e73b5b79321e231d3f91f3f7c9099035ad8ad48f9b6c11b5135d2cbf',
      point,
      referralCode
    );
    if (tonOauthResponse?.token) {
      setAccessToken(tonOauthResponse?.token);
      dispatch(
        updateAccount({
          token: tonOauthResponse?.token,
          expiresIn: ''
        })
      );
    } else {
      toast.error('Something went wrong!');
    }
    setIsLoadingLogin(false);
  };

  return {
    handleLogin,
    isLoadingLogin
  };
};
