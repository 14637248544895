import React from 'react';
import Dog1 from '@/assets/dogs/lv1.png';
import Dog2 from '@/assets/dogs/lv2.png';
import Dog3 from '@/assets/dogs/lv3.png';
import Dog4 from '@/assets/dogs/lv4.png';
import Dog5 from '@/assets/dogs/lv5.png';
import { useAccountInfoContext } from '@/contexts/account-info.context';

const dogList = [Dog1, Dog2, Dog3, Dog4, Dog5];
export const HomeDog = () => {
  const { userProfile } = useAccountInfoContext();
  return (
    <div className='w-full flex justify-center'>
      <img src={dogList[userProfile?.boost_core_level - 1 || 0] || Dog1} className='w-full max-w-[350px]' alt='' />
    </div>
  );
};
