import Storage from '@/assets/boost/storage.png';
import HyperDog from '@/assets/boost/hyperdog.png';
import HolyBoost from '@/assets/boost/holy_boost.png';
export const moreBoostInfo = (from: any, to: any) => {
  const value: any = {
    Core: {
      description: 'Increase passive mining speed',
      upgradeDes: 'Upgrade your Rocket, earn more HYPER, and give it a fresh new look!',
      unit: 'HYPER/hour',
      fromDes: `Mine ${from} HYPER/hour`,
      toDes: `Mine ${to} HYPER/hour`,
      img: HyperDog
    },
    Multiple: {
      description: 'Multiply your HYPER with each claim',
      upgradeDes: 'Multiply your HYPER with each claim',
      unit: 'x',
      fromDes: `x${from} current mine rate`,
      toDes: `x${to} current mine rate`,
      img: HolyBoost
    },
    Storage: {
      description: 'Increase time between claims',
      upgradeDes: 'Give yourself more time to explore life—stretch the timer and claim less often!',
      unit: 'x',
      fromDes: `Claim every ${from}h`,
      toDes: `Claim every ${to}h`,
      img: Storage
    }
  };
  return value;
};

export const coreBoost = {
  0: {}
};

export const boostNow = ['Core', 'Storage', 'Multiple'];
