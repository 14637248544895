import React, { PropsWithChildren, memo, useEffect, useState } from 'react';
import { Layout as LayoutAnt } from 'antd';
import { Wrapper } from './layout.styled';
const { Content } = LayoutAnt;
import { useNavigate } from 'react-router';
import WebApp from '@twa-dev/sdk';
import { Loading } from '../app-loading/app-loading';
import Tab from './components/tab';
import { useAccountInfoContext } from '@/contexts/account-info.context';
import { useSelector } from 'react-redux';
import { selectToken } from '@/redux';
interface ILayoutProps extends PropsWithChildren {
  className?: string;
  showStatusBar?: boolean;
  isLoading?: boolean;
}
const LayoutComponent = ({ children, className, showStatusBar = true, isLoading = false }: ILayoutProps) => {
  const [isAllLoaded, setIsAllLoaded] = useState(false);
  const { claimableReward, userQuest } = useAccountInfoContext();
  const token = useSelector(selectToken);
  useEffect(() => {
    setIsAllLoaded(false);
    if (isLoading) return;
    setTimeout(() => {
      setIsAllLoaded(true);
    }, 10000);
    Promise.all(
      Array.from(document.images)
        .filter((img) => !img.complete)
        .map(
          (img) =>
            new Promise((resolve) => {
              img.onload = img.onerror = resolve;
            })
        )
    ).then(() => {
      setIsAllLoaded(true);
    });
  }, [isLoading]);
  const navigate = useNavigate();
  useEffect(() => {
    if (!WebApp?.ready) return;
    WebApp.expand();
    WebApp.enableClosingConfirmation();
  }, []);

  console.log('claimableReward', claimableReward);
  return (
    <Wrapper>
      {showStatusBar && <Tab />}
      {(!isAllLoaded || !token || !userQuest) && <Loading />}
      {/* {isClaimShowPopup && <ClaimPopup />} */}
      <Content className={`content flex flex-col  ${className}`}>{children}</Content>
    </Wrapper>
  );
};

export const Layout = memo(LayoutComponent);
