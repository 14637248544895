import styled from 'styled-components';
import QuestBtn from '@/assets/quest/btn-bg.png';
export const Wrapper = styled.div`
  width: 100vw !important;
  min-height: 100svh;
  background-size: 100% 100%;
  padding: 24px;
  background: linear-gradient(180deg, #402957 0%, #10051c 100%);
  .quest-btn {
    background: url(${QuestBtn});
    background-size: 100% 100%;
  }
`;
