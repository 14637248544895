import { Layout } from '@/components/layout/layout';
import { Wrapper } from './quest.styled';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as RefreshIcon } from '@/assets/common-icon/refresh.svg';
import Button from '@/components/button/button';
import { Popup } from 'antd-mobile';
import { ReactComponent as CheckIcon } from '@/assets/common-icon/check-ic.svg';
import { useOnOff } from '@/hooks/use-on-off';
import { DailyCheckin } from './components/daily-checkin';
import { useStateCallback } from '@/hooks/use-state-callback';
import { SocialQuest } from './components/social-quest';
import { UserRepository } from '@/repository/user.repository';
import WebApp from '@twa-dev/sdk';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { selectToken } from '@/redux';
import useLocalStorage from 'use-local-storage';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import Gem from '@/assets/logo/gems.png';
import { useAccountInfoContext } from '@/contexts/account-info.context';
import Twitter from '@/assets/social/twitter.svg';
import Tele from '@/assets/social/telegram.svg';
import Hyper from '@/assets/social/hyperlaunch.svg';
import General from '@/assets/social/general.svg';
import Youtube from '@/assets/social/youtube.svg';
import Discord from '@/assets/social/discord.svg';
import Ref from '@/assets/tab/referral.png';
import League from '@/assets/tab/quest.png';

const progressQuest = ['LEAGUE'];

const imgMapping: any = {
  general: General,
  twitter: Twitter,
  telegram: Tele,
  discord: Discord,
  youtube: Youtube,
  hyperlaunch: Hyper,
  league: League,
  'ref quest': Ref,
  web: Hyper
};

export const QuestComponent = () => {
  const navigate = useNavigate();
  const { userQuest, refetchQuest, refetchProfile } = useAccountInfoContext();
  const { isOn: isDailyCheckin, turnOn: turnOnDailyCheckin, turnOff: turnOffDailyCheckin } = useOnOff(false);
  const { isOn: isSocialQuest, turnOn: turnOnSocialQuest, turnOff: turnOffSocialQuest } = useOnOff(false);
  const [activeQuest, setActiveQuest] = useStateCallback<any>(undefined);
  const token = useSelector(selectToken);

  const [activeCategory, setActiveCategory] = useState('HYPER QUEST');

  const { data: allQuests, refetch: refetchAllQuest } = useQuery({
    queryKey: ['retrieveAllQuest', token],
    queryFn: () => UserRepository.getAllQuest(),
    retry: false,
    refetchInterval: false,
    enabled: !!token
  });

  const category = useMemo(() => {
    const _category: any = [
      {
        cate: 'HYPER QUEST',
        des: 'Join our communities, engage & claim your rewards.'
      }
    ];
    allQuests?.map((quest: any) => {
      if (_category.findIndex((qt: any) => qt?.cate === quest?.category) === -1) {
        _category.push({
          cate: quest?.category,
          des: quest?.description
        });
      }
    });
    return _category;
  }, [allQuests]);

  console.log('category', category);

  console.log('allQuests', allQuests);
  const { data: claimableQuest } = useQuery({
    queryKey: ['retrieveUserClaimableQuest', token],
    queryFn: () => UserRepository.getClaimableQuest(),
    retry: false,
    refetchInterval: false,
    enabled: !!token
  });
  console.log('claimableQuest', claimableQuest);
  const [isLoadingRefetch, setIsLoadingRefretch] = useState(false);

  const [lastCheck, setLastCheck] = useLocalStorage('last-check-tele', 0);

  const handleCheckTele = useCallback(
    async (channelID: string, quest: string, isToast?: boolean) => {
      if (!WebApp?.initDataUnsafe?.user?.id) return;
      const isJoin = userQuest?.findIndex((x: any) => x?.quest === quest);
      if (!isJoin) {
        const res = await UserRepository.checkTelegramQuest(WebApp?.initDataUnsafe?.user?.id?.toString(), channelID);
        if (res?.joined) {
          const resOnboard = await UserRepository.updateQuest(quest);
          if (resOnboard) {
            refetchQuest();
            if (channelID !== '-1' && isToast) toast.success('Quest done!');
          } else {
            if (channelID !== '-1' && isToast) toast.error('Something went wrong! Try again later');
          }
        } else {
          if (channelID !== '-1' && isToast) toast.error('Verified failed');
        }
      }
      setLastCheck(new Date().getTime());
    },
    [userQuest, refetchQuest]
  );

  useEffect(() => {
    const now = new Date().getTime();
    if (now - lastCheck > 1000 * 60 * 5) {
      userQuest?.map((quest: any) => {
        if (quest?.channel_id) {
          handleCheckTele(quest?.channel_id, quest?.quest, false);
        }
      });
    }
  }, [userQuest]);

  const [isLoading, setIsLoading] = useState(-1);

  const allQuestMapped = allQuests
    ?.filter((task: any) => {
      const index = userQuest?.findIndex((x: any) => x?.quest === task?.quest);
      return task.category === activeCategory && index === -1;
    })
    ?.sort((a: any, b: any) => a.point - b.point);

  const [lastRefetch, setLastRefetch] = useState(0);

  return (
    <Layout>
      <Wrapper className='app-container flex flex-col items-center relative gap-5 !pb-[150px]'>
        <Popup visible={isDailyCheckin} onMaskClick={turnOffDailyCheckin} bodyStyle={{ height: '40vh' }}>
          <DailyCheckin handleClose={turnOffDailyCheckin} />
        </Popup>
        <Popup
          visible={isSocialQuest}
          onMaskClick={() => {
            turnOffSocialQuest();
            setActiveQuest(undefined);
          }}
          bodyStyle={{ height: '40vh' }}
        >
          <SocialQuest
            handleClose={() => {
              turnOffSocialQuest();
              setActiveQuest(undefined);
            }}
            quest={activeQuest}
            questImg={imgMapping?.[`${activeQuest?.image || activeQuest?.category?.toLowerCase()}`] || General}
          />
        </Popup>
        <div className='secondary-font font-normal text-[32px] text-center text-[#FFFFFF]'>Quest</div>
        <div className='w-full flex items-center gap-1 flex-wrap'>
          {category?.map((cate: any, key: number) => {
            return (
              <div
                key={key}
                className={`uppercase flex-1 whitespace-nowrap cursor-pointer bg-[#1A1224] rounded-lg font-normal text-sm text-[#FFFFFF] py-2 text-center flex items-center justify-center ${activeCategory === cate?.cate && '!bg-[#10051C] border-[2px] border-[#E1D6EF] !font-medium text-[#E1D6EF]'}`}
                onClick={() => {
                  setActiveCategory(cate?.cate);
                }}
              >
                {cate?.cate}
              </div>
            );
          })}
        </div>

        <div className='w-full flex justify-end'>
          <div
            className='flex items-center gap-2 refresh-icon cursor-pointer'
            onClick={async () => {
              if (lastRefetch !== 0 && new Date().getTime() - lastRefetch <= 3 * 1000) {
                toast.dismiss();
                toast.info('Refresh too fast!');
                return;
              }
              setIsLoadingRefretch(true);
              await refetchAllQuest();
              await refetchQuest();
              setIsLoadingRefretch(false);
              setLastRefetch(new Date().getTime());
            }}
          >
            <RefreshIcon />
            <span className='text-[#E1D6EF] text-sm font-medium '>
              {isLoadingRefetch ? 'Refreshing...' : 'Refresh'}{' '}
            </span>
          </div>
        </div>
        {allQuestMapped?.length === 0 && (
          <div className='flex w-full text-center justify-center items-center'>ALL DONE!</div>
        )}
        <div className='bg-[#10051C] w-full px-4 rounded-2xl'>
          {' '}
          {allQuestMapped?.map((item: any, key: number) => {
            const isClaimable = claimableQuest?.items?.findIndex((x: any) => x === item?.quest);
            console.log('item?.quest', item?.quest, claimableQuest, isClaimable);
            return (
              <>
                <div className='w-full justify-between items-center py-4 flex' key={key}>
                  <div className='flex items-center gap-2'>
                    <img
                      src={imgMapping?.[`${item.image || item.category?.toLowerCase()}`] || General}
                      className='w-10'
                      alt=''
                    />
                    <div className='flex flex-col items-start'>
                      <div className='font-medium text-xs text-[#FFFFFF]'>{item.quest}</div>
                      <div className='font-normal text-xs text-[#FFFFFF]'>{item.requirement}</div>
                      <div className='font-normal text-xs text-[#FFFFFF] flex items-center gap-1'>
                        Earn: <span className='font-medium text-xs text-[#948E9C]'>{item.point}</span>{' '}
                        <img src={Gem} className='w-6 mb-1'></img>
                      </div>
                    </div>
                  </div>
                  <Button
                    size={'sm'}
                    isLoading={isLoading === item.id}
                    variant={'secondary'}
                    disabled={item.category === 'LEAGUE' && isClaimable === -1}
                    onClick={async () => {
                      if (isLoading !== item.id) {
                        try {
                          if (isClaimable === -1) {
                            if (item.category === 'LEAGUE') {
                              navigate('/');
                              return;
                            }
                            if (item.category === 'REF QUEST') {
                              navigate('/referral');
                              return;
                            }
                            if (item.category === 'HYPER QUEST' || item.category === 'daily') {
                              let isJoin: any;
                              if (item.channel_id) isJoin = userQuest?.findIndex((x: any) => x?.quest === item);
                              if (item.category !== 'HYPER QUEST' && item.category !== 'daily') return null;
                              setActiveQuest(
                                {
                                  ...item,
                                  isClaimable,
                                  refetchQuest,
                                  isJoin
                                },
                                turnOnSocialQuest
                              );
                              return;
                            }
                          }
                          setIsLoading(item.id);
                          toast.dismiss();
                          await UserRepository.updateQuest(item.quest);
                          toast.success('Claimed!');
                          refetchQuest();
                          refetchProfile();
                          setIsLoading(-1);
                        } catch (error: any) {
                          toast.error(error?.message || error || 'Something went wrong! Try again later!');
                        }
                      }
                      return;
                    }}
                  >
                    {isClaimable !== -1 && <CheckIcon className='mb-[3px]' />}
                    {isClaimable !== -1 ? 'Claim' : progressQuest.includes(item?.category) ? 'Accumulating' : 'Go'}
                  </Button>
                </div>
                {(item?.progress || item?.progress === 0) &&
                  item?.category !== 'HYPER QUEST' &&
                  item?.category !== 'daily' && (
                    <div className='relative w-full mb-4 h-[5px] rounded-2xl bg-[#E1D6EF] -mt-2'>
                      <div
                        className='h-full bg-[#9454D5] rounded-2xl'
                        style={{
                          width: `${Math.min((Number(item.progress) * 100) / item.target, 100)}%`
                        }}
                      ></div>
                    </div>
                  )}
                {key < allQuestMapped?.length - 1 && <div className='h-[1px] w-full bg-[#E1D6EF]'></div>}
              </>
            );
          })}
        </div>

        {/* {activeCategory === 'HYPER QUEST' && (
          <>
            <div className='text-base font-normal text-[#1A1224] w-full text-start'>Daily task</div>
            <div
              className='app-linear-border p-4 rounded-lg w-full flex items-center gap-4 justify-between -mt-2 cursor-pointer'
              onClick={() => {
                toast.dismiss();
                toast.info('Coming soon!');
              }}
            >
              <div className='flex items-center gap-4'>
                <img src={Daily} className='w-10' alt='' />
                <div className='flex flex-col items-start'>
                  <div className='text-xs text-[#1A1224] font-medium'>Daily Checkin Reward</div>
                  <div className='font-medium text-xs text-[#1A1224]'>
                    <span className='font-normal text-xs text-[#948E9C]'>Earn:</span> +6.649M POINTs
                  </div>
                </div>
              </div>
              <ArrowLeft />
            </div>
            <div className='text-base font-normal text-[#1A1224] w-full text-start mt-4'>Join our Social</div>
            <div className='w-full grid grid-cols-2 gap-3'>
              {allQuestMapped?.map((quest: any, key: number) => {
                const isClaimable = claimableQuest?.items?.findIndex((x: any) => x === quest?.quest);
                let isJoin: any;
                if (quest.channel_id) isJoin = userQuest?.findIndex((x: any) => x?.quest === quest);
                if (quest.category !== 'HYPER QUEST') return null;
                return (
                  <div
                    key={key}
                    className='app-linear-border py-4 px-2 rounded-lg gap-3 flex flex-col items-center justify-between cursor-pointer'
                    onClick={() => {
                      setActiveQuest(
                        {
                          ...quest,
                          isClaimable,
                          refetchQuest,
                          isJoin
                        },
                        turnOnSocialQuest
                      );
                    }}
                  >
                    <img src={quest.image || Youtube} className='w-full rounded-lg' alt='' />
                    <div className='text-sm text-[#1A1224] font-medium text-center'>{quest.quest}</div>
                    <div className='quest-btn text-[#FFFFFF] text-sm font-medium w-full text-center h-0 pt-[35%] relative'>
                      <span className='absolute top-1/2 left-[55%] -translate-x-1/2 -translate-y-1/2'>
                        {quest.point}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )} */}
      </Wrapper>
    </Layout>
  );
};
export const Quest = memo(QuestComponent);
