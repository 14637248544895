import React, { memo, useCallback, useState } from 'react';
import { Wrapper } from './social-quest.styled';
import { ReactComponent as CloseIcon } from '@/assets/common-icon/close-ic.svg';
import Button from '@/components/button/button';
import WebApp from '@twa-dev/sdk';
import { UserRepository } from '@/repository/user.repository';
import { toast } from 'react-toastify';
import { useAccountInfoContext } from '@/contexts/account-info.context';
const SocialQuestComponent = ({
  handleClose,
  quest,
  questImg
}: {
  handleClose: () => void;
  quest: any;
  questImg: string;
}) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(-1);
  const [isDoing, setIsDoing] = useState(false);
  const [lastCheck, setLastCheck] = useState(0);
  const { refetchProfile } = useAccountInfoContext();
  const handleCheckTele = useCallback(
    async (channelID: string, isToast?: boolean) => {
      if (!WebApp?.initDataUnsafe?.user?.id) return;

      if (!quest.isJoin) {
        setIsLoading(1);
        const res = await UserRepository.checkTelegramQuest(WebApp?.initDataUnsafe?.user?.id?.toString(), channelID);
        if (res?.joined) {
          const resOnboard = await UserRepository.updateQuest(quest.id);
          if (resOnboard) {
            quest?.refetchQuest();
            if (channelID !== '-1' && isToast) toast.success('Quest done!');
          } else {
            if (channelID !== '-1' && isToast) toast.error('Something went wrong! Try again later');
          }
        } else {
          if (channelID !== '-1' && isToast) toast.error('Verified failed');
        }
      }
      setLastCheck(new Date().getTime());
      setIsLoading(-1);
    },
    [quest]
  );
  return (
    <Wrapper>
      <div className='w-full app-container flex flex-col items-center bg-[#FFFFFF] app-linear-border social-quest'>
        <CloseIcon className='self-end -mt-4 -mr-4 cursor-pointer' onClick={handleClose} />
        <img src={questImg} className='w-[120px]' alt='' />
        <div className='font-medium text-[#FFFFFF] text-base mt-6'>{quest?.quest}</div>
        <div className='mt-2 text-[#E1D6EF] text-[20px] font-medium text-center'>+ {quest?.point} HYPER</div>
        <Button
          className='w-full mt-2'
          isLoading={isLoading === 0}
          onClick={async () => {
            if (quest.isClaimable !== -1) {
              setIsLoading(0);
              if (isLoading !== quest.id) {
                await UserRepository.updateQuest(quest.quest);
                toast.success('Claimed!');
                quest?.refetchQuest();
                refetchProfile();
                setIsLoading(-1);
              }
              return;
            }
            if (quest.channel_id) {
              if (!WebApp?.initDataUnsafe?.user) window.open(quest.link);
              else {
                WebApp.openTelegramLink(quest.link);
              }
              return;
            }
            if (quest.link) {
              WebApp.openLink(quest.link);
            }
          }}
        >
          {quest?.isClaimable !== -1 ? 'Claim' : 'Do it now'}
        </Button>
        <div className='w-full h-[1px] my-6 bg-[#E1D6EF]'></div>
        <div className='text-center text-sm font-medium text-[#E1D6EF]'>Task Completed ?</div>
        <Button
          className='w-full mt-2'
          variant={'secondary'}
          isLoading={isLoading === 1}
          onClick={async () => {
            toast.dismiss();
            if (quest.isClaimable !== -1) {
              toast.success('Task done! Please claim!');
              return;
            }
            if (quest.channel_id) {
              handleCheckTele(quest.channel_id, true);
              return;
            }
            setIsLoading(1);
            toast.info('Server checking, Please wait in 10 second');
            setTimeout(async () => {
              await UserRepository.updateQuest(quest.quest);
              toast.success('Claimed!');
              quest?.refetchQuest();
              setIsLoading(-1);
              handleClose();
            }, 10000);
          }}
        >
          Check
        </Button>
      </div>
    </Wrapper>
  );
};

export const SocialQuest = memo(SocialQuestComponent);
