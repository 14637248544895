import { ENVS } from '@/config/env.config';

export const lowerCacheOptions = {
  gcTime: 5 * 60 * 1000,
  staleTime: 5 * 60 * 1000,
  meta: {
    persist: true
  },
  refetchOnMount: true
};

export const linkingKeyWork = '==';
export const convertStringToObjectParam = (str: string) => {
  const dataParams = str.split(linkingKeyWork);
  let objectParam: any = {};
  dataParams?.map((data: any) => {
    const _data = data?.split('=');
    if (_data?.[0] && _data?.[1]) objectParam[`${_data[0]}`] = _data[1];
  });
  return objectParam;
};

export const accountRate = ['80%', '50%', '30%', '15%'];

export const appConfig: any = !ENVS.VITE_ISTESTNET
  ? {
      twaUrl: 'https://t.me/hyper_rocket_bot/myapp'
    }
  : {
      twaUrl: 'https://t.me/hyperdoge_testnet_bot/myapp'
    };
