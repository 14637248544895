import styled from 'styled-components';
import HomeBg from '@/assets/home/home-bg.png';
export const Wrapper = styled.div`
  width: 100vw !important;
  min-height: 100svh;
  background-size: 100% 100%;
  padding: 24px;
  background: url(${HomeBg});
  background-size: cover;
  background-position: center center;
  .highlight-text {
    background: radial-gradient(50% 50% at 50% 50%, #ff3e41 0%, #ffe172 100%);
    font-family: 'Oxanium';
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .farming-button {
    background:
      linear-gradient(#e1d6ef 0 0) padding-box,
      linear-gradient(to bottom, #9454d5, rgba(148, 84, 213, 0)) border-box;
    border: 1px solid transparent;
    width: fit-content;
    min-width: 261px;
    height: 48px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    span {
      font-family: 'Oxanium';
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      color: var(--Dark-Purple, #10051c);
    }
    .loading-spine {
      width: 18px;
      aspect-ratio: 1;
      display: grid;
      border-radius: 50%;
      background:
        linear-gradient(0deg, rgb(0 0 0/50%) 30%, #0000 0 70%, rgb(0 0 0/100%) 0) 50%/8% 100%,
        linear-gradient(90deg, white 30%, #0000 0 70%, rgb(0 0 0/75%) 0) 50%/100% 8%;
      background-repeat: no-repeat;
      animation: l23 1s infinite steps(12);
    }
    .loading-spine::before,
    .loading-spine::after {
      content: '';
      grid-area: 1/1;
      border-radius: 50%;
      background: inherit;
      transform: rotate(30deg);
    }
    .loading-spine::after {
      opacity: 0.83;
      transform: rotate(60deg);
    }
    @keyframes l23 {
      100% {
        transform: rotate(1turn);
      }
    }
  }
`;
