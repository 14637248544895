import React, { memo, useCallback, useEffect, useState } from 'react';
import { Wrapper } from './claim.styled';
import Button from '@/components/button/button';
import { UserRepository } from '@/repository/user.repository';
import { toast } from 'react-toastify';
import { useAccountInfoContext } from '@/contexts/account-info.context';
import Icon from '@/assets/boost/claim.png';
import { useNavigate } from 'react-router';
import { ReactComponent as CloseIcon } from '@/assets/common-icon/close-ic.svg';
import Caution from '@/assets/boost/caution.png';
import Gem from '@/assets/logo/gems.png';
import { showNumber } from '@/utilities/number.utils';
const ClaimPopupComponent = ({ onClose, addedPoint }: { onClose?: () => void; addedPoint?: number }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { refetchProfile, refetchReward, claimableReward, isClaimShowPopup } = useAccountInfoContext();
  const navigate = useNavigate();

  const handleClaimReward = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await UserRepository.claim();
      if (res) {
        toast.success('Claimed Reward successfully!');
        refetchProfile();
        refetchReward();
        setIsLoading(false);
        if (onClose) {
          onClose();
        }
      }
    } catch (error: any) {
      toast.error(error?.message || error || 'Something went wrong! Try again later!');
      setIsLoading(false);
    }
  }, [refetchProfile, refetchReward]);

  useEffect(() => {
    refetchReward();
  }, []);

  console.log('claimableReward', claimableReward);
  return (
    <Wrapper>
      <div className='w-full app-container flex flex-col items-center bg-[#FFFFFF] app-linear-border claim-popup'>
        {!!onClose && (
          <div onClick={onClose} className='self-end cursor-pointer'>
            <CloseIcon />
          </div>
        )}
        <div className='relative'>
          <img src={Icon} className='w-[96px]' alt='' />
          {isClaimShowPopup && <img src={Caution} className='absolute left-[60px] w-12 top-[46px]' alt='' />}
        </div>

        <div className='font-medium text-[#FFFFFF] text-base mt-6'>
          {isClaimShowPopup ? 'Your storage is full' : ''}
        </div>
        {isClaimShowPopup && (
          <div className='font-normal text-sm text-[#948E9C] text-center'>
            You cannot earn more HYPER for now. Claim your HYPER or try to upgrade your storage
          </div>
        )}
        <div className='mt-1 text-[#FFFFFF] text-sm font-medium text-center flex items-center'>
          Reward:{' '}
          <span className='font-bold text-[#FFFFFF] ml-1 mr-[2px]'>
            {showNumber(Number(claimableReward?.point + (addedPoint || 0)))}
          </span>{' '}
          <img src={Gem} className='w-6 mb-1' alt='' />
        </div>

        <Button className='w-full mt-6' onClick={handleClaimReward} isLoading={isLoading}>
          Claim now
        </Button>
        <Button
          className='w-full mt-2 mb-3'
          variant={'secondary'}
          onClick={() => {
            if (onClose) {
              onClose();
            }
            if (isClaimShowPopup) {
              toast.info('Please claim first!');
            }
            navigate('/boost?type=Storage');
          }}
        >
          Upgrade Storage
        </Button>
      </div>
    </Wrapper>
  );
};

export const ClaimPopup = memo(ClaimPopupComponent);
