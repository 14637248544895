import React from 'react';
import { type VariantProps, cva } from 'class-variance-authority';
import { cn } from '@/utilities/string.utils';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  isLoading?: boolean;
  loadingText?: string;
}

const buttonVariants = cva(
  'inline-flex items-center justify-center gap-2 w-fit overflow-hidden rounded-[8px]  px-6 transition-all duration-100 active:translate-x-[3px] active:translate-y-[3px] disabled:pointer-events-none disabled:opacity-85',
  {
    variants: {
      variant: {
        default:
          'disabled:[box-shadow:0px_0px_rgb(157_0_239) disabled:opacity-40 active:[box-shadow:0px_0px_rgb(157_0_239)] default-button',
        secondary:
          'disabled:[box-shadow:0px_0px_rgb(44_254_238)] active:[box-shadow:0px_0px_rgb(44_254_238)]  secondary-button',
        activate:
          'disabled:[box-shadow:0px_0px_rgb(30_25_74)] active:[box-shadow:0px_0px_rgb(30_25_74)] activate-button'
      },
      size: {
        default: 'h-14 px-4 py-2 ',
        sm: 'h-6 px-2 text-xs font-normal w-fit gap-1'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
);

const Button = (props: ButtonProps): JSX.Element => {
  const { children, variant = 'default', size, className, isLoading, disabled, loadingText, ...rest } = props;

  return (
    <button {...rest} className={cn(buttonVariants({ variant, size, className }))} disabled={isLoading || disabled}>
      {isLoading ? (
        loadingText ? (
          loadingText
        ) : (
          <svg height='1.4em' viewBox='0 0 100 40'>
            <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
              <g transform='translate(-100.000000, -71.000000)'>
                <g transform='translate(95.000000, 71.000000)'>
                  <g transform='translate(5.000000, 0.000000)'>
                    <rect
                      fill={variant === 'default' ? '#9D00EF' : '#FFFFFF'}
                      x='20'
                      y='16'
                      width='8'
                      height='8'
                      rx='2'
                    >
                      <animate
                        attributeName='y'
                        from='16'
                        to='16'
                        dur='2s'
                        begin='0s'
                        repeatCount='indefinite'
                        values='16; 6; 26; 16; 16'
                        keyTimes='0; 0.1; 0.3; 0.4; 1'
                      ></animate>
                    </rect>
                    <rect
                      fill={variant === 'default' ? '#9D00EF' : '#FFFFFF'}
                      x='46'
                      y='16'
                      width='8'
                      height='8'
                      rx='2'
                    >
                      <animate
                        attributeName='y'
                        from='16'
                        to='16'
                        dur='2s'
                        begin='0.2s'
                        repeatCount='indefinite'
                        values='16; 6; 26; 16; 16'
                        keyTimes='0; 0.1; 0.3; 0.4; 1'
                      ></animate>
                    </rect>
                    <rect
                      fill={variant === 'default' ? '#9D00EF' : '#FFFFFF'}
                      x='72'
                      y='16'
                      width='8'
                      height='8'
                      rx='2'
                    >
                      <animate
                        attributeName='y'
                        from='16'
                        to='16'
                        dur='2s'
                        begin='0.4s'
                        repeatCount='indefinite'
                        values='16; 6; 26; 16; 16'
                        keyTimes='0; 0.1; 0.3; 0.4; 1'
                      ></animate>
                    </rect>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        )
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
