import { Layout } from '@/components/layout/layout';
import { Wrapper } from './check-in.styled';
import React, { memo } from 'react';
import { Step1 } from './components/step1';

export const CheckInComponent = () => {
  return (
    <Layout showStatusBar={false}>
      <Wrapper className='app-container !p-0 flex flex-col items-center justify-between relative gap-5'>
        <Step1 />
      </Wrapper>
    </Layout>
  );
};
export const CheckIn = memo(CheckInComponent);
