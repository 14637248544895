import React from 'react';
import Gem from '@/assets/logo/gems.png';
import { useAccountInfoContext } from '@/contexts/account-info.context';
import { showNumber } from '@/utilities/number.utils';
import Copy from '@/assets/common-icon/copy.png';
import { toast } from 'react-toastify';
export const HomeHeader = () => {
  const { userProfile } = useAccountInfoContext();

  // const estimatePoint = useCallback(() => {
  //   const point = Number(userProfile?.point);
  //   const coreValue = userProfile?.boost_core_value;
  //   const storageValue = userProfile?.boost_storage_value;
  //   const multipleValue = userProfile?.boost_multiple_value;

  //   const timeNow = new Date();
  //   const timeDiff = timeNow.getTime() - (latestUpdate || new Date().getTime());
  //   const timeDiffInHours = timeDiff / (1000 * 3600);
  //   const timeCanCount = Math.min(timeDiffInHours, storageValue);
  //   console.log('timeCanCount', timeCanCount, timeDiffInHours, storageValue);
  //   console.log('coreValue', coreValue);
  //   console.log('multipleValue', multipleValue);

  //   const pointCanClaim = coreValue * timeCanCount * multipleValue;
  //   return Math.floor(pointCanClaim) || 0;
  // }, [userProfile, latestUpdate]);
  // const countInterval = useMemo(() => {
  //   if (addedPoint === 0) return 500;
  //   return (new Date().getTime() - latestUpdate) / addedPoint;
  // }, [addedPoint, latestUpdate]);
  // useQuery({
  //   queryKey: [`estimateUserPoint`, estimatePoint],
  //   queryFn: () => {
  //     setAddedPoint(estimatePoint());
  //     return -1;
  //   },
  //   retry: false,
  //   refetchInterval: countInterval,
  //   enabled: !!userProfile && !isClaimShowPopup
  // });

  return (
    <div className='flex flex-col items-center relative w-full'>
      {/* <div className='flex items-center gap-[5px]'>
        <img src={Gem} className='w-10' alt='' />
        <span className='text-[#10051C] font-medium text-[40px]'>
          {showNumber(Number(userProfile?.point || 0) + claimableReward?.point + addedPoint)}
        </span>
      </div> */}
      <div className='bg-[#FFFFFF26] px-3 py-1 rounded-[24px] flex gap-2 items-center mb-4 font-normal text-sm'>
        UserID: {userProfile?.telegram_id}{' '}
        <img
          src={Copy}
          className='w-8 cursor-pointer p-2'
          onClick={async () => {
            try {
              toast.dismiss();
              await navigator.clipboard.writeText(userProfile?.telegram_id);
              await window.navigator.clipboard.writeText(userProfile?.telegram_id);
              toast.success('Copied');
            } catch (error) {
              toast.dismiss();
              toast.error('Something went wrong! Try again later!');
            }
          }}
          alt=''
        />
      </div>
      <span className='text-sm font-normal text-[#948E9C]'>HYPER Balance</span>
      <div className='flex items-center gap-[2px] mt-[2px]'>
        <img src={Gem} className='w-12' alt='' />
        <span className='text-[#FFFFFF] font-medium text-[32px] '>{showNumber(Number(userProfile?.point || 0))}</span>
      </div>
    </div>
  );
};
