import { Layout } from '@/components/layout/layout';
import { Wrapper } from './boost.styled';
import Gem from '@/assets/logo/gems.png';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import Storage from '@/assets/boost/storage.png';
import { ReactComponent as ArrowLeft } from '@/assets/common-icon/arr-left.svg';
import { useStateCallback } from '@/hooks/use-state-callback';
import { Popup } from 'antd-mobile';
import { BoostPopup } from './components/boost-popup';
import { useOnOff } from '@/hooks/use-on-off';
import { useSelector } from 'react-redux';
import { selectToken } from '@/redux';
import { UserRepository } from '@/repository/user.repository';
import { useAccountInfoContext } from '@/contexts/account-info.context';
import { showNumber } from '@/utilities/number.utils';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { boostNow, moreBoostInfo } from './boost.constant';
import { ClaimPopup } from '@/components/layout/components/claim';
const boostTypes = ['Core', 'Multiple', 'Storage'];
// const boostImg: any = {
//   Core: {
//     img: HyperDog,
//     subtitle: ''
//   },
//   Storage: {
//     img: Storage,
//     subtitle: ''
//   },
//   Multiple: {
//     img: HolyBoost,
//     subtitle: ''
//   }
// };
export const BoostComponent = () => {
  const { isOn: isClaim, turnOn: turnOnClaim, turnOff: turnOffClaim } = useOnOff(false);
  const [boost, setBoost] = useStateCallback<any>(undefined);
  const { userProfile, refetchProfile, allBoosts } = useAccountInfoContext();
  const { isOn: isBoost, turnOn: turnOnBoost, turnOff: turnOffBoost } = useOnOff(false);
  const token = useSelector(selectToken);

  const handleUpgradeBoost = useCallback(async (boost_type: string) => {
    try {
      await UserRepository.upgradeBoost(boost_type);
      toast.success('Upgrade boost successfully!');
      turnOffBoost();
      refetchProfile();
    } catch (error: any) {
      toast.dismiss();
      toast.error(error?.message || error || 'Something went wrong!');
    }
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const params = searchParams.get('type');
  console.log('params', params);

  console.log('al', allBoosts);
  const nextLevel: any = useMemo(() => {
    let Core: any = {};
    let Storage: any = {};
    let Multiple: any = {};
    allBoosts?.boosts?.map((boost: any) => {
      if (boost.boost_type === 'Multiple') {
        if (boost.level === userProfile?.boost_multiple_level + 1) {
          Multiple['next'] = boost;
        }
        if (boost.level === userProfile?.boost_multiple_level) {
          Multiple['current'] = boost;
        }
      }
      if (boost.boost_type === 'Core') {
        if (boost.level === userProfile?.boost_core_level + 1) {
          Core['next'] = boost;
        }
        if (boost.level === userProfile?.boost_core_level) {
          Core['current'] = boost;
        }
      }

      if (boost.boost_type === 'Storage') {
        console.log('haha');
        if (boost.level === userProfile?.boost_storage_level + 1) {
          Storage['next'] = boost;
        }
        if (boost.level === userProfile?.boost_storage_level) {
          Storage['current'] = boost;
        }
      }
    });
    return {
      Core,
      Storage,
      Multiple
    };
  }, [allBoosts, userProfile]);

  useEffect(() => {
    console.log('effect');
    if (!params) return;
    if (!nextLevel) return;
    if (searchParams.has('type')) {
      searchParams.delete('type');
      setSearchParams(searchParams);
    }
    if (!nextLevel?.[`${params}`]?.next) {
      toast.dismiss();
      toast.info(`${params} reached max level!`);
      return;
    }

    if (boostNow.includes(params)) {
      const _boost = {
        icon: moreBoostInfo(0, 1)?.[`${params}`]?.img || Storage,
        title: params,
        desc: moreBoostInfo(0, 1)?.[`${params}`]?.upgradeDes,
        from: nextLevel?.[`${params}`]?.current?.level,
        fromDesc: moreBoostInfo(nextLevel?.[`${params}`]?.current?.value, 1)?.[`${params}`]?.fromDes,
        to: nextLevel?.[`${params}`]?.next?.level,
        toDesc: moreBoostInfo(0, nextLevel?.[`${params}`]?.next?.value)?.[`${params}`]?.toDes,
        fee: nextLevel?.[`${params}`]?.next?.fee?.toLocaleString(),
        cta: 'Upgrade',
        fee_type: nextLevel?.[`${params}`]?.next?.fee_type,
        confirm: () => {
          handleUpgradeBoost(params);
        }
      };
      setBoost(_boost, turnOnBoost);
    }
  }, [params, nextLevel]);

  console.log('next', nextLevel);

  return (
    <Layout>
      <Wrapper className='app-container flex flex-col items-center relative gap-5'>
        <Popup visible={isClaim} onMaskClick={turnOffClaim} bodyStyle={{ height: '40vh' }}>
          <ClaimPopup onClose={turnOffClaim} />
        </Popup>
        <Popup visible={isBoost} onMaskClick={turnOffBoost} bodyStyle={{ height: '40vh' }}>
          <BoostPopup handleClose={turnOffBoost} boost={boost} />
        </Popup>
        <div className='flex flex-col items-center relative w-full'>
          <span className='text-[#E1D6EF] font-normal text-base'>Your Balance</span>
          <div className='flex items-center gap-[5px]'>
            <img src={Gem} className='w-10' alt='' />
            <span className='text-[#FFFFFF] font-medium text-[40px]'>
              {showNumber(Number(userProfile?.point || 0))}
            </span>
          </div>
        </div>
        {boostTypes?.map((type, key) => {
          if (!nextLevel?.[`${type}`]?.next)
            return (
              <div
                key={key}
                className='app-linear-border p-4 rounded-lg w-full flex items-center gap-4 justify-between cursor-pointer'
                onClick={() => {
                  toast.dismiss();
                  toast.info(`You reached maximum level`);
                }}
              >
                <div className='flex items-center gap-4'>
                  <img src={moreBoostInfo(0, 1)?.[`${type}`]?.img || Storage} className='w-10' alt='' />
                  <div className='flex flex-col items-start'>
                    <div className='text-sm text-[#FFFFFF] font-medium'>
                      {type} Level {nextLevel?.[`${type}`]?.current?.level}
                    </div>
                    <div className='font-normal text-sm text-[#E1D6EF]'>
                      {moreBoostInfo(nextLevel?.[`${type}`]?.current?.value, 1)?.[`${type}`]?.fromDes}
                    </div>
                    <div className='font-medium text-sm text-[#695481]'>You reached maximum level</div>
                  </div>
                </div>
              </div>
            );
          return (
            <div
              key={key}
              className='app-linear-border p-4 rounded-lg w-full flex items-center gap-4 justify-between cursor-pointer'
              onClick={() => {
                if (!nextLevel?.[`${type}`]) {
                  toast.info(`${type} reached max level!`);
                  return;
                }
                const _boost = {
                  icon: moreBoostInfo(0, 1)?.[`${type}`]?.img || Storage,
                  title: type,
                  desc: moreBoostInfo(0, 1)?.[`${type}`]?.upgradeDes,
                  from: nextLevel?.[`${type}`]?.current?.level,
                  fromDesc: moreBoostInfo(nextLevel?.[`${type}`]?.current?.value, 1)?.[`${type}`]?.fromDes,
                  to: nextLevel?.[`${type}`]?.next?.level,
                  toDesc: moreBoostInfo(0, nextLevel?.[`${type}`]?.next?.value)?.[`${type}`]?.toDes,
                  fee: nextLevel?.[`${type}`]?.next?.fee?.toLocaleString(),
                  cta: 'Upgrade',
                  fee_type: nextLevel?.[`${type}`]?.next?.fee_type,
                  confirm: () => {
                    handleUpgradeBoost(type);
                    refetchProfile();
                  }
                };
                setBoost(_boost, turnOnBoost);
              }}
            >
              <div className='flex items-center gap-4'>
                <img src={moreBoostInfo(0, 1)?.[`${type}`]?.img || Storage} className='w-10' alt='' />
                <div className='flex flex-col items-start'>
                  <div className='text-sm text-[#FFFFFF] font-medium'>
                    {type} Level {nextLevel?.[`${type}`]?.current?.level}
                  </div>
                  <div className='font-normal text-sm text-[#E1D6EF]'>
                    {moreBoostInfo(0, 1)?.[`${type}`]?.description}
                  </div>
                  <div className='flex items-center gap-[5px]'>
                    <img src={Gem} className='w-6' alt='' />
                    <span className='text-[#9454D5] font-medium text-sm'>
                      {nextLevel?.[`${type}`]?.next?.fee?.toLocaleString()}
                    </span>
                    <span className='font-normal text-sm text-[#E1D6EF]'>to upgrade </span>
                  </div>
                </div>
              </div>
              <ArrowLeft className='boost-arr' />
            </div>
          );
        })}

        {/* {allBoostsMapped?.map((item: any, key: number) => {
          return (
            <div
              key={key}
              className='app-linear-border p-4 rounded-lg w-full flex items-center gap-4 justify-between cursor-pointer'
              onClick={() => {
                if (!nextLevel?.[`${item.boost_type}`]) {
                  toast.info(`${item.boost_type} reached max level!`);
                  return;
                }
                const _boost = {
                  icon: moreBoostInfo(0, 1)?.[`${item.boost_type}`]?.img || Storage,
                  title: item.boost_type,
                  desc: moreBoostInfo(0, 1)?.[`${item.boost_type}`]?.upgradeDes,
                  from: 1,
                  fromDesc: moreBoostInfo(0, 1)?.[`${item.boost_type}`]?.fromDes,
                  to: 2,
                  toDesc: moreBoostInfo(0, nextLevel?.[`${item.boost_type}`]?.value)?.[`${item.boost_type}`]?.toDes,
                  fee: item.fee?.toLocaleString(),
                  cta: 'Upgrade',
                  confirm: () => {
                    handleUpgradeBoost(item.boost_type);
                    refetchProfile();
                  }
                };
                setBoost(_boost, turnOnBoost);
              }}
            >
              <div className='flex items-center gap-4'>
                <img src={moreBoostInfo(0, 1)?.[`${item.boost_type}`]?.img || Storage} className='w-10' alt='' />
                <div className='flex flex-col items-start'>
                  <div className='text-sm text-[#1A1224] font-medium'>
                    {item.boost_type} Level {item.level}
                  </div>
                  <div className='font-medium text-sm text-[#695481]'>
                    {moreBoostInfo(0, 1)?.[`${item.boost_type}`]?.description}
                  </div>
                  <div className='flex items-center gap-[5px]'>
                    <img src={Gem} className='w-6' alt='' />
                    <span className='text-[#10051C] font-medium text-sm'>{item.fee?.toLocaleString()}</span>
                    <span className='font-normal text-sm text-[#695481]'>to upgrade </span>
                  </div>
                </div>
              </div>
              <ArrowLeft />
            </div>
          );
        })} */}

        {/* <div
          className='app-linear-border p-4 rounded-lg w-full flex items-center gap-4 justify-between cursor-pointer'
          onClick={() => {
            const _boost = {
              icon: Storage,
              title: 'Storage',
              desc: 'Extend the filled time to claim less often',
              from: 1,
              fromDesc: 'Claim every 2h',
              to: 2,
              toDesc: 'Claim every 3h',
              fee: '0.02',
              cta: 'Upgrade',
              confirm: () => {}
            };
            setBoost(_boost, turnOnBoost);
          }}
        >
          <div className='flex items-center gap-4'>
            <img src={Storage} className='w-10' alt='' />
            <div className='flex flex-col items-start'>
              <div className='text-sm text-[#1A1224] font-medium'>Storage Level 1</div>
              <div className='font-medium text-sm text-[#695481]'>Increase time between claims</div>
              <div className='flex items-center gap-[5px]'>
                <img src={Gem} className='w-6' alt='' />
                <span className='text-[#10051C] font-medium text-sm'>0.25</span>
                <span className='font-normal text-sm text-[#695481]'>to upgrade </span>
              </div>
            </div>
          </div>
          <ArrowLeft />
        </div> */}

        {/* <div className='app-linear-border p-4 rounded-lg w-full flex items-center gap-4 justify-between cursor-pointer'>
          <div className='flex items-center gap-4'>
            <img src={HolyBoost} className='w-10' alt='' />
            <div className='flex flex-col items-start'>
              <div className='text-sm text-[#1A1224] font-medium'>Holy Boost</div>
              <div className='font-medium text-sm text-[#695481]'>Super boost speed 1.2x to 2.5x times</div>
              <div className='flex items-center gap-[5px]'>
                <img src={Gem} className='w-6' alt='' />
                <span className='text-[#10051C] font-medium text-sm'>0.25</span>
                <span className='font-normal text-sm text-[#695481]'>to upgrade </span>
              </div>
            </div>
          </div>
          <ArrowLeft />
        </div> */}
        {/* https://t.me/share/url?url=https://t.me/catizenbot/gameapp?startapp=rp_1365932&text=%F0%9F%92%B0Catizen%3A%20Unleash%2C%20Play%2C%20Earn%20-%20Where%20Every%20Game%20Leads%20to%20an%20Airdrop%20Adventure!%0A%F0%9F%8E%81Let%27s%20play-to-earn%20airdrop%20right%20now! */}
        {/* <div className='app-linear-border p-4 rounded-lg w-full flex items-center gap-4 justify-between cursor-pointer'>
          <div className='flex items-center gap-4'>
            <img src={HyperDog} className='w-10' alt='' />
            <div className='flex flex-col items-start'>
              <div className='text-sm text-[#1A1224] font-medium'>HyperRocket Level 1</div>
              <div className='font-medium text-sm text-[#695481]'>Upgrade HyperRocket</div>
              <div className='flex items-center gap-[5px]'>
                <img src={Gem} className='w-6' alt='' />
                <span className='text-[#10051C] font-medium text-sm'>0.25</span>
                <span className='font-normal text-sm text-[#695481]'>to upgrade </span>
              </div>
            </div>
          </div>
          <ArrowLeft />
        </div> */}
      </Wrapper>
    </Layout>
  );
};
export const Boost = memo(BoostComponent);
