import React, { memo, useState } from 'react';
import { Wrapper } from './boost-popup.styled';
import { ReactComponent as CloseIcon } from '@/assets/common-icon/close-ic.svg';
import Gem from '@/assets/logo/gems.png';
import Ref from '@/assets/tab/referral.png';
import Button from '@/components/button/button';
import { ReactComponent as ArrowLeft } from '@/assets/common-icon/arr-left.svg';
const BoostPopupComponent = ({ handleClose, boost }: { handleClose: () => void; boost: any }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  console.log('boost', boost);
  return (
    <Wrapper>
      <div className='w-full app-container flex flex-col items-center bg-[#FFFFFF] app-linear-border boost-popup'>
        <CloseIcon className='self-end -mt-4 -mr-4 cursor-pointer' onClick={handleClose} />
        <img src={boost?.icon} className='w-[64px]' alt='' />
        <div className='font-medium text-[#FFFFFF] text-base mt-6'>{boost?.title}</div>
        <div className='mt-1 text-[#E1D6EF] text-sm font-normal text-center'>{boost?.desc}</div>
        <div className='app-linear-border rounded-lg p-4 flex flex-col items-start w-full mt-6'>
          <div className='text-sm font-medium text-[#948E9C]'>Level {boost?.from}</div>
          <div className='text-sm font-normal text-[#E1D6EF]'>{boost?.fromDesc}</div>
        </div>
        <ArrowLeft className='rotate-[90deg] my-2' />
        <div className='app-linear-border rounded-lg p-4 flex flex-col items-start w-full'>
          <div className='text-sm font-medium text-[#948E9C]'>Level {boost?.to}</div>
          <div className='text-sm font-normal text-[#E1D6EF]'>{boost?.toDesc}</div>
        </div>

        <div className='w-full flex items-center justify-center gap-2 mt-6'>
          <div className='text-sm font-normal text-[#E1D6EF]'>Fee:</div>
          {boost?.fee_type === 'Point' ? (
            <img src={Gem} className='w-6' alt='' />
          ) : boost?.fee_type === 'Referal' ? (
            <img src={Ref} className='w-6' alt='' />
          ) : null}

          <div className='text-sm font-medium text-[#FFFFFF]'>{boost?.fee}</div>
        </div>
        <Button
          className='w-full mt-6'
          onClick={() => {
            boost?.confirm();
          }}
        >
          {boost?.cta}
        </Button>
      </div>
    </Wrapper>
  );
};

export const BoostPopup = memo(BoostPopupComponent);
