import { Layout } from '@/components/layout/layout';
import { Wrapper } from './home.styled';
import React, { memo } from 'react';
import { HomeHeader } from './components/home-header';
import { HomeDog } from './components/home-dog';
import { HomeInfo } from './components/home-info';
export const HomeComponent = () => {
  return (
    <Layout>
      <Wrapper className='app-container w-full h-[100svh] overflow-hidden gap-6 flex flex-col items-center justify-between relative'>
        <HomeHeader />
        <HomeDog />
        <div className='w-full h-[110px]'></div>
        {/* <HomeTabs /> */}
        <HomeInfo />
      </Wrapper>
    </Layout>
  );
};
export const Home = memo(HomeComponent);
